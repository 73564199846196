.page {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.content {
  margin-top: 20vh;
  padding: 0 16px;
  text-align: center;
}
.img {
  margin-bottom: 50px;
}
.title {
  color: #606676;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -1.45px;
}
.description {
  margin-top: 10px;
  color: #7b8a97;
}
.button {
  width: 260px;
  height: 62px;
  margin-top: 50px;
  border: 0;
  border-radius: 31px;
  background-color: #00cae8;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 18px;
  }
  .description {
    font-size: 12px;
    letter-spacing: -1px;
  }
}